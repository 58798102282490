// eslint-disable-next-line no-unused-vars
import React from 'react';
import './Card.css';

export const Card = ({personajes=[], person}) => {
    return (
        <div className="grid-card">
            {
                personajes.map((item, index) =>(
                    <div key={index} className="card">
                        <h5>{item.name}</h5>
                        <img src={item.URL} className='' alt={item.name} />
                        <p>{item.category}</p>
                        <p>{item.quote}</p>
                    </div>
                ))}
        </div>
    )
}












