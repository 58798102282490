// eslint-disable-next-line no-unused-vars
import React from "react";
import { ContCard } from "../../Layout/ContCard/ContCard";
import { Header } from "../../Ui/Header/Header";
import './Home.css'


export const Home = () => {
    return (
        <div className="home">
            <Header/>
            <ContCard/>
        </div>
    )
}







