import React from 'react';
import './App.css';
import { Home } from './Components/Pages/Home/Home';



function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
