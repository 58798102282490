// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from "react";
import './ContCard.css';
import { Card } from "../../Ui/Card/Card";

export const ContCard = () => {

    // eslint-disable-next-line no-unused-vars
    const[personajes,setPersonajes] = useState([])

    // eslint-disable-next-line no-unused-vars
    const urlApi = 'https://apichavo2.herokuapp.com'

    // eslint-disable-next-line no-unused-vars
    const fetchPersonajes = (url) =>{
        fetch(url)
        .then(response => response.json())
        // eslint-disable-next-line no-undef
        .then(data => setPersonajes(data))
        .catch(error => console.log(error))
    }
    useEffect(() =>{
        fetchPersonajes(urlApi)
    },[])

    return (
        <div className="ContCard">
            <Card personajes={personajes}/>
        </div>
    )
}
